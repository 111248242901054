import React from 'react';
import { createQt, postQuestions, closeChat, createIntroMsg } from "../api/service";
import { marked } from "marked";
import { useTranslation } from 'react-i18next'; // Importer useTranslation

var clickTimer0;
var clickTimer1;
var clickTimer2;
var clickTimer3;
var inputTimer1;
var inputTimer2;
let sent = false;
let ended = false;

const renderer = new marked.Renderer();
renderer.link = function ({ href, title, tokens }) {
  const displayText = href.startsWith("mailto:") ? href.replace("mailto:", "") : href;
  return `<a href="${href}" title="${title || ''}" target="_blank">${displayText}</a>`;
};
marked.setOptions({
  renderer: renderer
});

const ActionProvider = ({ createChatBotMessage, setState, children }) => {
  const { t } = useTranslation(); // Utiliser useTranslation pour accéder aux traductions

  const states = children.props.children.props.state;

  // Sauvegarde des messages dans le localStorage
  const saveMessages = (messages) => {
    const chatbotsData = JSON.parse(localStorage.getItem('chatbots')) || {};
    const chatbotId = localStorage.getItem('chatbot_id_active');
    if (chatbotId) {
      chatbotsData[chatbotId] = {
        ...chatbotsData[chatbotId],
        chat_messages: messages // Mettre à jour les messages dans l'objet chatbots
      };

      localStorage.setItem('chatbots', JSON.stringify(chatbotsData));
    }
  };

  // Fonction principale pour gérer les interactions avec le chatbot
  const next = async (id, message) => {
    const icon = document.querySelector('.react-chatbot-kit-chat-btn-send-icon');
    const btn = document.querySelector('.react-chatbot-kit-chat-btn-send');
    const input = document.querySelector('.react-chatbot-kit-chat-input');
    const loading_point = document.createElement('span');
    const next_point = document.createElement('span');
    loading_point.className = 'loading-points';
    loading_point.append(next_point);
    icon.style.display = 'none';
    btn.append(loading_point);
    input.disabled = true;
    const chatbotsData = JSON.parse(localStorage.getItem('chatbots')) || {};
    const chatbotId = localStorage.getItem('chatbot_id_active'); 

    let idQt = states.idQt;
    if (!idQt && chatbotsData[chatbotId]['chat_id']) {
      idQt = chatbotsData[chatbotId]['chat_id']
      setState((prev) => {
        return { ...prev, ...{ idQt: idQt } };
      });
    }

    // Création d'une nouvelle session de chatbot
    if (!idQt) {
      if (chatbotsData[chatbotId]) {
        const chatbot = chatbotsData[chatbotId];

        const pq = await createQt(chatbot.id);
        idQt = pq.data.id;
        await createIntroMsg(idQt, { content: chatbot?.messages?.intro || t('chatbotIntro') });
        chatbotsData[chatbotId]['chat_id'] = idQt
        localStorage.setItem('chatbots', JSON.stringify(chatbotsData));
        setState((prev) => {
          return { ...prev, ...{ idQt: pq.data.id } };
        });
      } else {
        console.error("Chatbot non trouvé dans le localStorage");
      }
    }

    let question = {};
    let finalValue = null;

    input.placeholder = t('checkingRequest');
    window.clearTimeout(inputTimer1);
    window.clearTimeout(inputTimer2);
    inputTimer1 = window.setTimeout(() => {
      input.placeholder = t('aiTyping');
    }, 5 * 1000);
    inputTimer2 = window.setTimeout(() => {
      input.placeholder = t('arrivingSoon');
    }, 15 * 1000);

    let rel = false;
    question.question = '';
    if (idQt) {
      const pqapi = await postQuestions(idQt, { "content": message });
      question.question = pqapi.data.response;

      if (pqapi.data.status === 'end' || pqapi.data.status === 'finished' || pqapi.data.status === 'cancelled') {
        question.widget = 'restartButton';
        ended = true;
      }

      if (pqapi.data.status === 'get_user_infos') {
        rel = true;
      }
    }

    window.clearTimeout(inputTimer1);
    window.clearTimeout(inputTimer2);
    input.placeholder = t('writeMessage');
    icon.style.display = 'block';
    loading_point.remove();

    if (question && question.final) {
      finalValue = question.value;
      setState(prevState => ({
        ...prevState,
        skinType: finalValue
      }));
      id = question.next;
    }

    let str = marked(question.question);
    let obj = {
      payload: { uid: id, id: question.id },
      loading: true,
      terminateLoading: true,
      withAvatar: true,
      delay: question.delay || null,
      widget: question.widget || null,
    };

    addMessageToState(createChatBotMessage(str, obj));

    if(rel && !sent && (chatbotId === '70' || chatbotId === '73')) {
      sent = true;
      setTimeout(() => {
        addMessageToState(createChatBotMessage('Dr ' + (chatbotId === '73' ? 'DUPARC' : 'Maareck') + ', votre pharmacien référent à la Pharmacie de l\'Hôtel de Ville, reste à votre disposition pour toute information complémentaire. Découvrez également un large choix de compléments alimentaires soigneusement sélectionnés, avec les conseils personnalisés d\'un pharmacien expert.', obj));
        setTimeout(async () => {
          let ss = 'À la suite de votre diagnostic, pour recevoir des conseils personnalisés de votre pharmacie, merci de bien vouloir nous communiquer vos coordonnées.';
          const pqapi = await postQuestions(idQt, {"content": ss});
          addMessageToState(createChatBotMessage(pqapi.data.response, obj));
        }, 2000)
      }, 2000)
    }

    clearTimeOut(idQt, str, obj);
    document.body.addEventListener("keydown", () => {
      clearTimeOut(idQt, str, obj);
    });
  };

  // Fonction pour gérer le minuteur de timeout
  const clearTimeOut = (idQt, str, obj) => {
    window.clearTimeout(clickTimer0);
    window.clearTimeout(clickTimer1);
    window.clearTimeout(clickTimer2);
    window.clearTimeout(clickTimer3);
    if(ended)
      return
    clickTimer1 = window.setTimeout(async () => {
      addMessageToState(createChatBotMessage(str, obj));
     // await createIntroMsg(idQt, { content: str }) ne renvoie plus le message a l api
    }, 120 * 1000);
    clickTimer2 = window.setTimeout(async () => {
      addMessageToState(createChatBotMessage(t('stillThere'), obj)); 
      await createIntroMsg(idQt, { content: t('stillThere') })
    }, 240 * 1000);
    clickTimer3 = window.setTimeout(async () => {
      const close = await closeChat(idQt);
      obj.widget = 'restartButton'
      addMessageToState(createChatBotMessage(close.data.output, obj));
    }, 360 * 1000);
  };

  // Fonction pour créer et ajouter un message dans l'état
  const createBotMessage = (msg, options = {}) => {
    const message = createChatBotMessage(msg, options);
    addMessageToState(message);
  };

  const addMessageToState = (message) => {
    document.querySelector('.react-chatbot-kit-chat-input').disabled = false;
    saveMessages([...states.messages, message]);
    setState(prevState => ({
      ...prevState,
      messages: [...prevState.messages, message]
    }));
  };

  return (
    <div>
      {React.Children.map(children, (child) => {
        return React.cloneElement(child, {
          actions: { next, createBotMessage },
        });
      })}
    </div>
  );
};

export default ActionProvider;
