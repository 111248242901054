import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

// Importation des fichiers de traduction
import translationEn from '././assets/i18n/en.json';
import translationFr from './assets/i18n/fr.json';
import translationDe from './assets/i18n/de.json';
import translationEs from './assets/i18n/es.json';

i18n
  .use(initReactI18next) // Passe i18n à React
  .init({
    resources: {
      en: {
        translation: translationEn
      },
        fr: {
            translation: translationFr
        },
        de: {
            translation: translationDe
        },
        es: {
            translation: translationEs
        }
    },
    lng: 'fr', // langue par défaut
    fallbackLng: 'fr', // langue de secours
    interpolation: {
      escapeValue: false // React déjà échappe les valeurs
    }
  });

export default i18n;
